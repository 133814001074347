import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
    CenteredQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../../components/InnerPage';

const DieSeele = ({ data }) => (
    <InnerPage title="Die Seele" description="">
        <IntroHolder
            title="Die Seele"
            subtitle="Das Wesen eines jeden Menschen ist seine mit Vernunft begabte und unsterbliche Seele."
            picture={data.introImage}
            breadcrumbs={{
                grandparent: 'Woran Bahá’í glauben',
                parent: 'Die Natur des Menschen',
                'current-item': 'Die Seele',
            }}
        >
            <IntroQuote href="/woran-bahai-glauben/natur-des-menschen/die-seele/zitate">
                Wenn eine Seele das geistige Leben in sich trägt, dann bringt
                sie gute Früchte hervor ...
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Zum Wesen eines jeden Menschen gehört eine mit Vernunft
                    begabte und unsterbliche Seele. Das Wesen der Seele können
                    wir nicht begreifen. Sie ist, so sagt{' '}
                    <Link to="/woran-bahai-glauben/bahaullah-und-sein-anspruch/bahaullah/">
                        Bahá’u’lláh
                    </Link>{' '}
                    „ein Zeichen Gottes, ein himmlischer Edelstein, dessen
                    Wirklichkeit die gelehrtesten Menschen nicht zu begreifen
                    vermögen“<sup>2</sup>.
                </p>
                <div>
                    <p>
                        Bahá’u’lláh verdeutlicht das Verhältnis zwischen Seele
                        und Körper am Beispiel der Sonne:
                    </p>
                </div>
                <CenteredQuote referenceNumber="3">
                    Die Seele des Menschen ist die Sonne, die seinen Leib
                    erleuchtet und ihm Nahrung spendet […].
                </CenteredQuote>
                <div>
                    <p>
                        Die der Seele innewohnenden Kräfte ermöglichen es dem
                        Menschen, Fortschritte zu machen. ‘Abdu’l-Bahá erklärt:
                    </p>
                </div>
                <CenteredQuote referenceNumber="4">
                    Die Kraft der mit Vernunft begabten Seele kann die
                    Wirklichkeiten der Dinge entdecken, die Eigentümlichkeiten
                    der Geschöpfe begreifen und die Geheimnisse des Daseins
                    ergründen. Alle Wissenschaften, Künste, Wunderwerke,
                    Einrichtungen, Entdeckungen, Unternehmungen und alles Wissen
                    kommen von der angewandten Geisteskraft der mit Vernunft
                    begabten Seele.
                </CenteredQuote>
                <div>
                    <p>
                        Unser Dasein kann mit einem Spiegel verglichen werden,
                        der sich der göttlichen Quelle zuwendet und ihr Licht
                        widerspiegelt.{' '}
                        <Link to="/woran-bahai-glauben/natur-des-menschen/gebet-und-meditation/">
                            Gebet
                        </Link>
                        , Erwerb von Wissen, Übereinstimmung von Worten und
                        Taten sowie ständiges Bemühen zur Vervollkommnung
                        unseres Charakters ermöglichen durch ihre Kraft die
                        Weiterentwicklung der Seele zu Gott hin. Dadurch wird
                        der Mensch immer mehr in die Lage versetzt, sein Leben
                        am{' '}
                        <Link to="/gemeinsames-handeln/andacht-und-dienst/">
                            Dienst an der Menschheit
                        </Link>{' '}
                        auszurichten.
                    </p>
                </div>
                <div>
                    <p>
                        Durch den Tod wird die Seele vom Körper getrennt. Sie
                        ist unsterblich und setzt ihre ewige Reise der
                        Weiterentwicklung fort.
                    </p>
                </div>
                <CenteredQuote referenceNumber="5">
                    Wenn ein Käfig, in dem ein Vogel ist, zerstört wird, bleibt
                    der Vogel unverletzt.
                </CenteredQuote>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Ansprachen in Paris 31:8</p>
                    <p>2. ‘Abdu’l-Bahá, Ansprachen in Paris 29:13</p>
                    <p>3. Bahá’u’lláh, Ährenlese 80:4</p>
                    <p>4. ‘Abdu’l-Bahá, Beantwortete Fragen, S. 213</p>
                    <p>5. ‘Abdu’l-Bahá, Ansprachen in Paris 20:10</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default DieSeele;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "Kachel_Die-Seele-1-1-e1502009531531.jpg" }
        ) {
            ...fluidImage
        }
    }
`;
